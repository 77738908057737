var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "header" }, [
    _c("div", { staticClass: "header-content" }, [
      _c(
        "div",
        { staticClass: "header-wrap  header-left-wrap" },
        [
          _c("p", [_vm._v("欢迎来到学茶商城")]),
          _vm.$store.state.user.name
            ? [
                _c(
                  "el-dropdown",
                  { attrs: { trigger: "click" } },
                  [
                    _c("span", { staticClass: "el-dropdown-link" }, [
                      _vm._v(" " + _vm._s(_vm.$store.state.user.name)),
                      _c("i", {
                        staticClass: "el-icon-arrow-down el-icon--right",
                      }),
                    ]),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c(
                          "el-dropdown-item",
                          {
                            nativeOn: {
                              click: function ($event) {
                                return _vm.logout($event)
                              },
                            },
                          },
                          [_vm._v("退出")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "p",
                  { staticStyle: { color: "#FF0030" } },
                  [
                    _c("router-link", { attrs: { to: "/login" } }, [
                      _vm._v("请登录"),
                    ]),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _c("div", { staticClass: "header-wrap header-right-wrap" }, [
        _c(
          "p",
          [
            _c("router-link", { attrs: { to: "/myorder" } }, [
              _vm._v("个人中心"),
            ]),
          ],
          1
        ),
        _c("i", [_vm._v("|")]),
        _c(
          "p",
          [
            _c("router-link", { attrs: { to: "/carpage" } }, [
              _vm._v("购物车"),
            ]),
          ],
          1
        ),
        _c("i", [_vm._v("|")]),
        _c(
          "p",
          {
            staticStyle: { cursor: "pointer" },
            on: { click: _vm.$showNoAuthMsg },
          },
          [_vm._v("消息中心")]
        ),
        _c("i", [_vm._v("|")]),
        _c(
          "p",
          {
            staticStyle: { cursor: "pointer" },
            on: { click: _vm.$showNoAuthMsg },
          },
          [_c("span", [_vm._v("手机版")])]
        ),
        _c("i", [_vm._v("|")]),
        _c(
          "p",
          {
            staticStyle: { cursor: "pointer" },
            on: { click: _vm.$showNoAuthMsg },
          },
          [_vm._v("商务合作")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }