var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c(
            "el-header",
            { staticClass: "header-layout" },
            [
              _c("Header"),
              _c("div", { staticClass: "header-container" }, [
                _c(
                  "div",
                  { staticClass: "header-wrap" },
                  [
                    _c("el-image", {
                      staticClass: "logo",
                      attrs: { src: _vm.logo },
                      on: {
                        click: function ($event) {
                          return _vm.linkHome()
                        },
                      },
                    }),
                    _c(
                      "ul",
                      { staticClass: "menu-container" },
                      _vm._l(_vm.menuList, function (item, index) {
                        return _c(
                          "li",
                          {
                            key: index,
                            staticClass: "menu-list",
                            class: { active: _vm.activeIndex == index },
                            on: {
                              click: function ($event) {
                                return _vm.linkInfor(item, index)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.title))]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c("el-main", { staticClass: "main-layout" }, [_c("router-view")], 1),
          _c("el-footer", { staticClass: "footer-layout" }, [
            _c("div", { staticClass: "footer-container" }, [
              _c("div", { staticClass: "footer-wrap" }, [
                _c("div", {
                  staticClass: "copyright",
                  domProps: { innerHTML: _vm._s(_vm.copyright) },
                }),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }