// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./iconfont.woff2?t=1678429356992");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./iconfont.woff?t=1678429356992");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./iconfont.ttf?t=1678429356992");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "@font-face {\r\n  font-family: \"iconfont\"; /* Project id 3944182 */\r\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2'),\r\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff'),\r\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('truetype');\r\n}\r\n\r\n.iconfont {\r\n  font-family: \"iconfont\" !important;\r\n  font-size: 16px;\r\n  font-style: normal;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n}\r\n\r\n.icon-cai:before {\r\n  content: \"\\e68f\";\r\n}\r\n\r\n.icon-cai1:before {\r\n  content: \"\\e690\";\r\n}\r\n\r\n.icon-dianzan:before {\r\n  content: \"\\e8ad\";\r\n}\r\n\r\n.icon-dianzan1:before {\r\n  content: \"\\e8c3\";\r\n}\r\n\r\n", ""]);
// Exports
module.exports = exports;
